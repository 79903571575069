<template>
  <section
    id="orderList"
    class="d-flex justify-center">
    <div class="sketch-list-content-box">
      <img-popup-preview
        v-model="imgPopup"
        :img-src="currentPreviewImg" />
      <header-tool-bar
        :value="filter"
        state="manufacturing"
        @input="filterUpdated($event)"
        @search-clicked="filterUpdated($event)" />
      <v-row class="content-row">
        <v-col cols="12">
          <h1 class="title">
            รายการผลิต
          </h1>
        </v-col>
        <v-col cols="7">
          <div class="table-wrap">
            <v-data-table
              v-model="orderSelected"
              :headers="orderHeader"
              :items="orderList"
              :loading="tableLoading"
              :options.sync="options"
              :items-per-page="options.itemsPerPage"
              :server-items-length="itemsLength"
              item-key="id"
              class="sketch-table"
              dense
              show-select
              hide-default-footer
              @update:sort-by="optionsUpdated()"
              @update:sort-desc="optionsUpdated()">
              <template #body="{ headers, items, isSelected, select }">
                <table-body-prototype
                  :items="items"
                  :headers="headers"
                  :is-selected="isSelected"
                  :select="select"
                  :search-text="filter.search"
                  :prototype-selected-id="prototypeSelected.id"
                  blank-item="-"
                  @click:row="expandOrder($event)">
                  <template #[`item.order.orderId`]="{ value }">
                    {{ value | paddingNumber() }}
                  </template>
                  <template #[`item.manufac.createdAt`]="{ value }">
                    {{ getDate(value) }}
                  </template>
                  <template #[`item.order.orderDate`]="{ value }">
                    {{ getDate(value) }}
                  </template>
                  <template #[`item.order.targetDate`]="{ value }">
                    {{ getDate(value) }}
                  </template>
                  <template #[`item.order.sellPrice`]="{ item }">
                    {{ item.order.sellPrice | showFullPriceFormat() }}
                  </template>
                  <template #[`item.diffDays`]="{ item }">
                    {{ getDiff(item) }}
                  </template>
                  <template #[`item.manufac.manufacStatus`]="{ item, value }">
                    <v-chip
                      v-if="isOverdue(item.order.targetDate, value)"
                      class="manufac-status-btn"
                      :ripple="false"
                      color="#ff121e"
                      elevation="0"
                      x-small>
                      Overdue
                    </v-chip>
                    <v-chip
                      v-else-if="value === 'on_progress'"
                      class="manufac-status-btn"
                      :ripple="false"
                      color="#f7f063"
                      elevation="0"
                      x-small>
                      On Progress
                    </v-chip>
                    <v-chip
                      v-else-if="value === 'delivered_ch'"
                      class="manufac-status-btn"
                      :ripple="false"
                      color="#4cc76f"
                      elevation="0"
                      x-small>
                      Delivered CH
                    </v-chip>
                    <v-chip
                      v-else-if="value === 'delivered_th'"
                      class="manufac-status-btn"
                      :ripple="false"
                      color="#4cc76f"
                      elevation="0"
                      x-small>
                      Delivered TH
                    </v-chip>
                    <v-chip
                      v-else-if="value === 'stopped'"
                      class="manufac-status-btn"
                      :ripple="false"
                      color="#b5b3b3"
                      elevation="0"
                      x-small>
                      STOPPED
                    </v-chip>
                  </template>
                  <template #[`item.action`]="{ item }">
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          x-small
                          v-on="on">
                          <v-icon small>
                            mdi-dots-horizontal
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          link
                          :to="`/prototype/order/edit/${item.id}`">
                          <v-list-item-title>EDIT</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          link
                          @click="tryToDeleteSketch(item.id)">
                          <v-list-item-title>DELETE</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </table-body-prototype>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col cols="5">
          <div class="detail-wrap pa-0">
            <detail-box
              :prototype-selected="prototypeSelected"
              @img-click="imgPreview($event)"
              @remake-sketch-click="remakeSketch($event)" />
          </div>
        </v-col>
        <v-col
          cols="7"
          class="d-flex flex-row gap">
          <div class="select-status-wrap">
            <v-select
              v-model="statusSelected"
              label="Select Status"
              class="status-select"
              :items="statusList"
              dense
              outlined
              hide-details />
          </div>
          <v-btn
            :disabled="statusSelected === '' || orderSelected.length === 0"
            outlined
            @click="changeStates(orderSelected, statusSelected)">
            เปลี่ยนสถานะรายการที่เลือก
          </v-btn>
          <v-btn
            :disabled="isDisableBtn"
            outlined
            :loading="tableLoading"
            @click="createMapping()">
            Create Product
          </v-btn>
          <v-btn
            :disabled="isDisableBtn || !isSameVersion"
            outlined
            @click="calculateMargin()">
            {{ `คำนวณ Margin (${orderSelected.length})` }}
          </v-btn>
          <v-btn
            :disabled="isDisableBtn || !isAllNew"
            outlined
            @click="exportFabricOrders()">
            พิมพ์ใบสั่ง
          </v-btn>
          <v-btn
            :disabled="isDisableBtn"
            outlined
            @click="exportPdf()">
            Export PDF
          </v-btn>
          <!-- <v-btn
            :disabled="isDisableBtn"
            outlined
            @click="exportCsv()">
            Export CSV
          </v-btn> -->
          <v-btn
            :disabled="isDisableBtn"
            outlined
            @click="exportPackinglist()">
            Export PACKINGLIST
          </v-btn>
        </v-col>
        <v-col cols="5">
          <v-pagination
            v-model="options.page"
            :total-visible="5"
            :length="pageCount"
            :disabled="tableLoading"
            color="secondary"
            @input="pageUpdated($event)" />
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import PrototypeProvider from '@/resources/PrototypeProvider'
import FabricOrdersProvider from '@/resources/FabricOrdersProvider'
import ProductProvider from '@/resources/ProductProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SizeChartProvider from '@/resources/SizeChartProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import ManufacProvider from '@/resources/ManufacProvider'
import HeaderToolBar from '../components/HeaderToolBar.vue'
import DetailBox from '../components/DetailBox.vue'
import ImgPopupPreview from '../components/ImgModalPreview.vue'
import TableBodyPrototype from '../components/TableBodyPrototype.vue'

const PrototypeService = new PrototypeProvider()
const FabricOrdersService = new FabricOrdersProvider()
const ProductService = new ProductProvider()
const ProductAttributeService = new ProductAttributeProvider()
const ManufacService = new ManufacProvider()
const SizeChartService = new SizeChartProvider()

export default {
  components: {
    HeaderToolBar,
    DetailBox,
    ImgPopupPreview,
    TableBodyPrototype
  },
  data () {
    return {
      productAttributes: {
        brands: [],
        categories: [],
        colors: [],
        size: [],
        tags: [],
        collections: []
      },
      imgPopup: false,
      currentPreviewImg: '',
      statusSelected: '',
      statusList: [
        { header: 'States' },
        { text: 'SKETCH', value: 'sketch' },
        { text: 'ORDER', value: 'order' },
        { divider: true },
        { header: 'Manufacture Status' },
        { text: 'ON PROGRESS', value: 'on_progress' },
        { text: 'DELIVERED CH', value: 'delivered_ch' },
        { text: 'DELIVERED TH', value: 'delivered_th' },
        { text: 'STOPPED', value: 'stopped' }
      ],
      options: {
        sortBy: ['manufac.createdAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15
      },
      pageCount: 0,
      itemsLength: 0,
      currentPage: 1,
      prototypeSelected: {},
      orderSelected: [],
      orderHeader: [
        {
          text: 'Sketch ID',
          sortable: false,
          align: 'center',
          value: 'sketch.sketchId'
        },
        {
          text: 'Order ID',
          sortable: false,
          align: 'center',
          value: 'order.orderId'
        },
        {
          text: 'Product ID',
          sortable: false,
          align: 'center',
          value: 'order.productId'
        },
        {
          text: 'Product Factory ID',
          sortable: false,
          align: 'center',
          value: 'order.productFactoryId'
        },
        {
          text: 'Price',
          sortable: false,
          align: 'center',
          value: 'order.sellPrice'
        },
        {
          text: 'Collection',
          sortable: false,
          align: 'center',
          value: 'sketch.collectionName'
        },
        {
          text: 'Factory Name',
          sortable: false,
          align: 'center',
          value: 'order.factory.name'
        },
        {
          text: 'Designer',
          sortable: false,
          align: 'center',
          value: 'sketch.designer.name'
        },
        {
          text: 'Merchandiser',
          sortable: false,
          align: 'center',
          value: 'sketch.merchandiser.name'
        },
        {
          text: 'Graphic',
          sortable: false,
          align: 'center',
          value: 'sketch.graphic.name'
        },
        {
          text: 'Creative',
          sortable: false,
          align: 'center',
          value: 'sketch.stylist.name'
        },
        {
          text: 'Classification',
          sortable: false,
          align: 'center',
          value: 'sketch.classification'
        },
        {
          text: 'Order Date',
          sortable: false,
          align: 'center',
          value: 'order.orderDate'
        },
        {
          text: 'Target Date',
          sortable: false,
          align: 'center',
          value: 'order.targetDate'
        },
        {
          text: 'Days',
          sortable: false,
          align: 'center',
          value: 'diffDays'
        },
        {
          text: 'Status',
          sortable: false,
          align: 'center',
          value: 'manufac.manufacStatus'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'action'
        }
      ],
      orderList: [],
      prototypes: [],
      tableLoading: false
    }
  },
  computed: {
    isDisableBtn () {
      return this.orderSelected.length < 1
    },
    isAllNew () {
      return this.orderSelected.every((o) => 'colors' in o.order)
    },
    isSameVersion () {
      const newData = this.isAllNew
      const oldData = this.orderSelected.every((o) => !('colors' in o.order))

      return newData || oldData
    },
    filter () {
      const { query } = this.$route
      this.setPage(query?.page || 1)
      return {
        search: query?.search || '',
        designer: query?.designer || '',
        merchandiser: query?.merchandiser || '',
        graphic: query?.graphic || '',
        stylist: query?.stylist || '',
        factory: query?.factory || '',
        collection: query?.collection || '',
        itemsPerPage: query?.itemsPerPage || 15,
        state: 'manufacturing'
      }
    }
  },
  created () {
    this.getOrders()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setDarkMode: 'Style/setDarkMode',
      setIsWebpSupported: 'Style/setIsWebpSupported',
      initStore: 'Store/initStore',
      setPrototypes: 'Prototypes/setPrototypes',
      setFabricOrder: 'Prototypes/setFabricOrder',
      setOrders: 'Prototypes/setOrders'
    }),
    isNewSchema (item) {
      return 'colors' in item.order
    },
    setPage (page) {
      this.options.page = Number(page)
    },
    getDate (value) {
      if (value !== '-') {
        return this.$dayjs(value).format('DD/MM/YYYY')
      }
      return value
    },
    getDiff (item) {
      if (item?.order?.orderDate) {
        const date = this.$dayjs().diff(this.$dayjs(item.order.orderDate), 'day')
        return date >= 0 ? date + 1 : '-'
      }
      return '-'
    },
    expandOrder ({ item }) {
      this.prototypeSelected = item
    },
    filterUpdated (filters) {
      this.options.itemsPerPage = Number(filters.itemsPerPage)
      this.getDataFromFilters({
        ...filters,
        page: 1
      })
    },
    pageUpdated (page) {
      this.getDataFromFilters({ ...this.filter, page })
    },
    getDataFromFilters (filters) {
      this.$router.push({
        name: this.$route.name,
        query: { ...filters }
      }).catch(() => {})
      this.getOrders(this.options)
    },
    optionsUpdated () {
      this.getOrders()
    },
    async getOrders (options = this.options, filter = this.filter) {
      this.tableLoading = true
      try {
        const { data } = await ManufacService.getManyManufac({ ...options, ...filter })
        this.itemsLength = data.total
        this.pageCount = data.pages
        this.prototypes = data.results
        this.orderList = data.results
        if (this.orderList.length > 0) {
          this.prototypeSelected = this.orderList[0]
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.tableLoading = false
      }
    },
    async createManyManufacture () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'LOADING...'
        })

        const ids = this.orderSelected.map((order) => order.id)
        const { message } = await ManufacService.createManufacturing(ids)

        if (message === 'done') {
          this.setSnackbar({
            value: true,
            message: 'sketches has been changed to orders',
            type: 'success'
          })
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.$router.push({ name: 'OrderFormList' })
      }
    },
    async searchClicked (filter) {
      this.filter = filter
      this.getOrders()
    },
    async remakeSketch (id) {
      this.$router.push({ name: 'CreateSketch', params: { id, remake: true } })
    },
    async changeStates (orders, state) {
      const ids = orders.map((order) => order.id)

      this.setLoading({
        active: true,
        clickAble: false,
        message: 'CHANGING STATE...'
      })
      try {
        if (state === 'sketch' || state === 'order') {
          await PrototypeService.changeState(ids, state)
        } else {
          await ManufacService.changeManufactureStatus(ids, state)
        }
        this.setSnackbar({
          value: true,
          message: 'Change status success.',
          type: 'success'
        })
        this.getOrders()
        if (state === 'sketch' || state === 'order') {
          this.selectAndPushPath(state)
        }
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    tryToDeleteSketch (id) {
      this.setModal({
        value: true,
        title: 'DeleteOrder',
        message: 'Do you want to delete this manufacture?',
        confirmText: 'Delete',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.deleteSketch(id)
      })
    },
    async deleteSketch (id) {
      this.setLoading({
        active: true,
        clickAble: false,
        message: 'GETTING SKETCHES...'
      })
      try {
        const { message } = await PrototypeService.deleteSketch(id)
        this.getOrders()

        if (message === 'done') {
          this.setSnackbar({
            value: true,
            message: 'Sketch has been deleted.',
            type: 'success'
          })
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    selectAndPushPath (state) {
      if (state === 'sketch') {
        this.$router.push({ name: 'SketchList' })
      } else if (state === 'order') {
        this.$router.push({ name: 'OrderFormList' })
      }
    },
    imgPreview (src) {
      this.currentPreviewImg = src
      this.imgPopup = true
    },
    async exportFabricOrders () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'EXPORTING...'
        })
        const { data } = await FabricOrdersService.createOne({ prototypes: this.orderSelected })

        this.setFabricOrder(data)
        this.setLoading({ active: false })
        window.open(this.$router.resolve({ name: 'ExportFabricOrder' }).href, '_blank')
      } catch (error) {
        console.error('exportFabricOrders', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async exportPdf () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'EXPORTING...'
        })

        this.setPrototypes(this.orderSelected)
        this.setLoading({ active: false })
        window.open(this.$router.resolve({ name: 'ExportManufacture' }).href, '_blank')
      } catch (error) {
        console.error('exportPdf', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async exportPackinglist () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'EXPORTING...'
        })

        const isAllHaveProductId = this.orderSelected.every((prototype) => !!prototype.order.productId)

        if (!isAllHaveProductId) {
          throw Error('Some orders don\'t have a product id.')
        }

        const modelIds = this.orderSelected.map((prototype) => {
          const splitted = prototype.order.productId.split('-')

          return splitted[splitted.length - 1].trim()
        })
        const { data } = await ProductService.getManyProductByModel({ modelIds })
        const tmpMapped = this.orderSelected.map((prototype) => {
          const product = data.find((pd) => prototype.order.productId === pd.model)
          const fitting = Array.isArray(prototype.order.fitting) ? [...prototype.order.fitting] : [prototype.order.fitting]
          const lastFitting = fitting[fitting.length - 1]

          return {
            ...prototype,
            image: product?.photoUrls[0] || lastFitting.images[lastFitting.images.length - 1] || ''
          }
        })

        this.setPrototypes(tmpMapped)
        window.open(this.$router.resolve({ name: 'ExportPackingList' }).href, '_blank')
      } catch (error) {
        console.error('exportPackinglist', error)
        this.setSnackbar({
          value: true,
          message: `${error?.code || 400}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    exportCsv () {
      if (this.orderSelected.length > 0) {
        const preExport = this.orderSelected.map((order) => ({
          'Sketch ID': order.sketch?.sketchId || '-',
          'Order ID': order.order?.orderId || '-',
          'Product ID': order.order?.productId || '-',
          'Product Factory ID': order.order?.productFactoryId || '-',
          'Category': order.sketch?.category?.name || '-',
          'Collection': order.sketch?.collectionName || '-',
          'Designer': order.sketch?.designer?.name || '-',
          'Merchandiser': order.sketch?.merchandiser?.name || '-',
          'Graphic': order.sketch?.graphic?.name || '-',
          'Creative': order.sketch?.stylist?.name || '-',
          'Factory': order.order?.factory?.name || '-',
          'Classification': order.sketch?.classification || '-',
          'Date': this.$dayjs(order?.createdAt).format('YYYY-MM-DD') || '-',
          'OrderDate': this.$dayjs(order?.order?.orderDate).format('YYYY-MM-DD') || '-',
          'TargetDate': this.$dayjs(order?.order?.targetDate).format('YYYY-MM-DD') || '-',
          'สถานะ': order?.order?.orderStatus || '-',
          'โน๊ต': order.sketch?.note || '-',
          'Sizing Detail': this.preExportSizingDetail(order.order.sizeDetail),
          'Product SKUS': this.preExportProductSkus(order.order.productSkus),
          'ราคาขาย': order.order?.sellPrice || '-',
          'ราคาผ้า': order.order?.clothesCostPerMetr || '-',
          'ราคาผลิต': (order.order?.manufacCountry === 'thailand' ? order.order?.manufacCost : order.order?.manufacCost * 5) || '-',
          'โรงงาน': order.order?.manufacCountry || '-'
        }))

        const options = {
          filename: `MANUFACTURES(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
          showLabels: true,
          useKeysAsHeaders: true
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(preExport)
      }
    },
    preExportSizingDetail (sizeDetail) {
      const result = sizeDetail.map((detail) => (
        detail.options.map((option) => `${detail.size}(${option.key})=${option.value}`)
      ))

      return result.toString()
    },
    preExportProductSkus (productSkus) {
      const result = productSkus.map((detail) => (
        `[size=${detail.size}, color=${detail.color}, quantity=${detail.quantity}, ratio=${detail.ratio}]`
      ))

      return result.toString()
    },
    removeUnuseData (_obj) {
      const obj = _obj
      const keys = Object.keys(obj)

      keys.forEach((key) => {
        if (obj[key] && typeof obj[key] === 'object' && JSON.stringify(obj[key]) !== '{}') {
          obj[key] = this.removeUnuseData(obj[key])
        } else if (!obj[key] || JSON.stringify(obj[key]) === '{}') {
          delete obj[key]
        }
      })

      return JSON.stringify(obj) !== '{}' ? obj : null
    },
    diffDays (date1, date2) {
      return this.$dayjs(date1).diff(this.$dayjs(date2), 'day') || '-'
    },
    isOverdue (date, status) {
      return this.$dayjs().isAfter(this.$dayjs(date)) && status === 'on_progress'
    },
    mappingProductForm (prototype) {
      if (!this.productAttributes.brands.length) {
        const error = {
          code: 400,
          message: 'Please add brand before create product.'
        }

        throw error
      }

      const collection = this.productAttributes.collections.find(
        (v) => `${v.name || ''}`.trim().toUpperCase() === `${prototype.sketch?.collectionName || ''}`.trim().toUpperCase()
      )
      const products = prototype.order.colors.map((c) => {
        const productColor = this.productAttributes.colors.find((attr) => attr.name.toLowerCase() === c.color.toLowerCase())
        const productName = productColor
          ? `${prototype.order.productId}.${productColor.id}`
          : prototype.order.productId

        const fitting = (prototype.order?.fitting && Array.isArray(prototype.order.fitting))
          ? [...prototype.order?.fitting]
          : [prototype.order?.fitting]

        let lastFitting = fitting[fitting.length - 1]

        for (let i = (fitting.length - 1); i >= 0; i--) {
          const { images } = fitting[i]

          if (images.length) {
            lastFitting = fitting[i]
            break
          }
        }

        const product = {
          name: prototype.order?.productFactoryId || productName,
          model: productName,
          categories: [],
          gwCollection: collection?.id || null,
          brand: prototype.sketch?.brand || this.productAttributes.brands[0].name,
          photoUrls: lastFitting?.images || [],
          weight: 1,
          width: 1,
          length: 1,
          height: 1,
          tags: [],
          skus: []
        }

        const skusForm = []
        const productSkus = prototype.order.productSkus.filter((p) => p.color === c.color)

        for (const sku of productSkus) {
          const attributeSize = this.productAttributes.size.find((size) => size.name === sku.size)

          if (!attributeSize) {
            const error = {
              code: 400,
              message: 'Got an unexpect size, Please check your size and try again.'
            }

            throw error
          }

          sku.size = {
            id: attributeSize.id,
            name: attributeSize.name,
            type: attributeSize.type
          }

          const formIndex = skusForm.findIndex((r) => r.color && r.color === sku.color)

          if (formIndex === -1) {
            sku.color = sku.color.toLowerCase()

            const color = this.mapColor(this.productAttributes.colors, sku.color)

            if (color && color.id) {
              skusForm.push({
                color,
                size: [sku.size],
                images: prototype.sketch.images
              })
            } else {
              const error = {
                code: 400,
                message: `${sku.color} not found in mapColor()`
              }

              throw error
            }
          } else {
            skusForm[formIndex].size.push(sku.size)
          }
        }

        product.skus = this.mapSkusTable(skusForm, prototype.order.productSkus, product, prototype.order.sellPrice)

        return product
      })

      return products
    },
    mapSkusTable (skusForm, skusTable, product, sellPrice) {
      const newArr = []

      for (const skuForm of skusForm) {
        if (skuForm.color && skuForm.color.name) {
          for (let j = 0; Array.isArray(skuForm.size) && j < skuForm.size.length; j++) {
            const skuIndex = skusTable.findIndex((sku) => {
              const isSkuIs = sku.id && skuForm.size[j].skuId && sku.id === skuForm.size[j].skuId
              const isColor = sku.color.name === skuForm.color.name
              const isSize = sku.size.name === skuForm.size[j].name

              return isSkuIs || (isColor && isSize)
            })

            const isDuplicate = skuIndex !== -1
            let skuCode = ''

            if (skusTable[skuIndex] && skusTable[skuIndex].code) {
              skuCode = skusTable[skuIndex].code
            } else if (!skuForm.size[j].skuId && product.model) {
              skuCode = `${product.model}.${skuForm.size[j].id}`
            }

            const data = {
              id: isDuplicate ? skusTable[skuIndex].id : undefined,
              color: {
                id: skuForm.color.id,
                code: skuForm.color.code,
                name: skuForm.color.name,
                imageUrl: skuForm.color.imageUrl
              },
              size: skuForm.size[j],
              price: isDuplicate ? skusTable[skuIndex].price : sellPrice,
              cost: isDuplicate ? skusTable[skuIndex].cost : undefined,
              code: skuCode,
              ratio: isDuplicate ? skusTable[skuIndex].ratio : 2.8,
              status: isDuplicate ? skusTable[skuIndex].status : 'active',
              isOnWeb: isDuplicate ? skusTable[skuIndex].isOnWeb : false,
              images: skuForm.images && skuForm.images.length > 0 ? skuForm.images : []
            }

            newArr.push(data)
          }
        }
      }

      return newArr
    },
    mapColor (colors, colorName) {
      for (let i = 0; i < colors.length; i++) {
        const color = colors[i]

        if (color.name === colorName) {
          return color
        }

        if (Array.isArray(color.children) && color.children.length > 0) {
          const newColor = this.mapColor(color.children, colorName)

          if (newColor && newColor.id) {
            return newColor
          }
        }
      }

      return null
    },
    async getAttributes () {
      const { data } = await ProductAttributeService.getAllProductAttribute()

      this.productAttributes.colors = Array.isArray(data.colors) ? this.getColor(data.colors) : []
      this.productAttributes.brands = Array.isArray(data.brands) ? data.brands : []
      this.productAttributes.size = Array.isArray(data.size) ? data.size : []
      this.productAttributes.tags = Array.isArray(data.tags) ? data.tags : []
      this.productAttributes.categories = Array.isArray(data.categories) ? data.categories : []
      this.productAttributes.collections = Array.isArray(data.collections) ? data.collections : []

      return data
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: getImageOrGradientCss(color)
      }))
    },
    mappingSkus (prototype, createdProducts) {
      const colors = [...new Set(prototype.order.productSkus.map((product) => product.color))]
      const mappedSize = prototype.order.productSkus.map((pd) => {
        const product = createdProducts.find((p) => p.colors[0] === pd.color)
        const foundSku = product.skus.find((sku) => sku.size.name.toLowerCase() === pd.size.name.toLowerCase())
        const size = foundSku.size.name

        return {
          ...pd,
          productSku: foundSku,
          size
        }
      })

      return colors.map((color) => {
        const product = createdProducts.find((p) => p.colors[0] === color)

        return {
          color,
          skus: mappedSize.filter((pd) => pd.color === color),
          product,
          productSellsuki: null
        }
      })
    },
    async createMapping () {
      try {
        this.tableLoading = true

        await this.getAttributes()

        await Promise.all(this.orderSelected.map(async (prototype) => {
          const { data } = await PrototypeService.getOneSketchById(prototype.id)
          const createdProducts = await this.createProduct(data)
          const mappedSkus = this.mappingSkus(data, createdProducts)

          return this.saveMapping(prototype, mappedSkus)
        }))
      } catch (error) {
        console.error('createMapping', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        this.tableLoading = false
      }
    },
    async createProduct (prototype) {
      try {
        const mappedProduct = this.mappingProductForm(prototype)
        const createdProducts = await Promise.all(mappedProduct.map(async (mp) => {
          let { data: product } = await ProductService.getProductsForMapping({ search: mp.model })
          product = product.results.filter((p) => p.model === mp.model)

          if (product.length > 1) {
            const error = {
              code: 400,
              message: 'Duplicate Product.'
            }

            throw error
          } else if (product.length === 1) {
            return product[0]
          }

          const { data } = await ProductService.createProduct({
            ...mp,
            categories: [],
            productPrototype: {
              id: prototype.id,
              category: prototype.sketch?.category?.name || '',
              subCategory: prototype.order?.subCategory?.name || '',
              designer: prototype.sketch?.designer?.name || '',
              merchandiser: prototype.sketch?.merchandiser?.name || '',
              graphic: prototype.sketch?.graphic?.name || '',
              stylist: prototype.sketch?.stylist?.name || '',
              factory: prototype.order?.factory?.name || '',
              margin: prototype.order?.productSkus[0]?.ratio || mp.skus[0].ratio
            }
          })

          return data
        }))

        return createdProducts
      } catch (error) {
        console.error('createProduct', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        this.tableLoading = false
        return null
      }
    },
    async saveMapping (prototype, mappedSkus) {
      const productSkus = []
      const prototypeId = prototype.id
      const { productId } = prototype.order

      mappedSkus.forEach((mappedSku) => {
        const payload = mappedSku.skus.map((sku) => ({
            ...sku,
            productSellsuki: {
              productId: mappedSku.product.id,
              skuId: sku.productSku.id,
              name: mappedSku.product.name,
              color: mappedSku.color,
              size: sku.size,
              option: sku.productSku.option,
              mappedDate: new Date(),
              code: sku.productSku.code,
              photos: sku.productSku.images
            }
          }))

        productSkus.push(...payload)
      })

      const payload = {
        id: prototypeId,
        sizeUnit: prototype.order?.sizeUnit || prototype.sketch?.sizeUnit || '',
        productId,
        productSkus
      }

      return this.mapProduct(payload)
    },
    getSkuOptions (field, product, sku) {
      switch (field) {
        case 'color':
          if (product.variant1 === 'Color' || product.variant1 === 'color') {
            return sku.option1
          } if (product.variant2 === 'Color' || product.variant2 === 'color') {
            return sku.option2
          }
          return null

        case 'size':
          if (product.variant1 === 'Size' || product.variant1 === 'size') {
            return sku.option1
          } if (product.variant2 === 'Size' || product.variant2 === 'size') {
            return sku.option2
          }
          return null

        default:
          return sku.option3
      }
    },
    calculateMargin () {
      this.setOrders(this.orderSelected)
      this.$router.push({ name: 'MarginCalculate' })
    },
    async mapProduct (payload) {
      try {
        const productIds = []

        for (const product of payload.productSkus) {
          if (product.productSellsuki.productId && !productIds.includes(product.productSellsuki.productId)) {
            productIds.push(product.productSellsuki.productId)
          }
        }

        if (productIds.length) {
          const { data } = await PrototypeService.mapProduct(payload)

          const sizeChart = data.order.sizeDetail.map((detail) => ({
            header: detail.size,
            rows: detail.options
          }))

          await Promise.all(productIds.map(async (id) => {
            const sizeChartForm = {
              productId: id,
              sizeChart,
              sizeUnit: payload.sizeUnit
            }

            const { data: foundSizeChart } = await SizeChartService.getSizeChartByProductId(id)

            if (foundSizeChart) {
              return SizeChartService.updateSizeChart({
                ...sizeChartForm,
                id: foundSizeChart.id
              })
            }

            return SizeChartService.createSizeChart(sizeChartForm)
          }))

          this.setSnackbar({
            value: true,
            message: 'Mapped Success',
            type: 'success'
          })
        } else {
          const error = {
            code: 400,
            message: 'Product is empty.'
          }
          throw error
        }
      } catch (error) {
        console.error('mapProduct', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.tableLoading = false
      }
    }
  }
}
</script>

<style scoped>
.v-data-table--dense.sketch-table > .v-data-table__wrapper > table > tbody > tr > td {
  cursor: pointer;
}
.sketch-table .sketch-table-col {
  height: 32px !important;
  cursor: pointer;
}
.sketch-table .sketch-table-header {
  text-align: center;
  font-weight: bold;
  color: black !important;
}

.sketch-table.sketch-table-row {
  cursor: pointer;
}

.sketch-list-content-box {
  position: relative;
  padding: 15px 15px;
  /* background-color: #fff; */
  width: 100%;
  /* max-width: 1375px; */
}

.content-row {
  position: relative;
  padding: 10px 0;
  background-color: #fff;
}

.gap {
  gap: 8px;
  flex-wrap: wrap;
}

.table-wrap {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: fit-content;
}

.detail-wrap {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: 532px;
  overflow-y: scroll;
}

.action-col {
  color: rgb(83, 83, 240);
  cursor: pointer;
}

.field-label {
  font-weight: bold;
}

.sketch-image {
  margin: 4px 12px 12px;
  height: 120px;
}

.v-data-table.sketch-table {
  height: 100%
}

.sketch-table .v-data-table__wrapper {
  height: 100%
}

div.select-status-wrap {
  width: 145px;
}

.manufac-status-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>
